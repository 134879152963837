import React, { useState } from 'react';
import { Link } from 'gatsby';
import './recuperar-senha.css';
import Seo from '../components/Seo';

const RecoverPassword = () => {
    const [email, setEmail] = useState('');

    const handleRecover = (event) => {
        event.preventDefault();
        console.log('Recover password for', email);
    };

    return (
        <div className="login-container">
            <Seo title="Recuperar Senha | Prontuário Fácil" description="Recupere sua senha do Prontuário Fácil e acesse sua conta novamente." />
            <main className="login-content">
                <form className="login-form" onSubmit={handleRecover}>
                    <h1>Recuperar Senha</h1>
                    <label htmlFor="email">E-mail</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <button type="submit" className="login-button">Enviar</button>
                    <Link to="/login" className="back-to-login-link">Voltar para Login</Link>
                </form>
            </main>
        </div>
    );
};

export default RecoverPassword;
